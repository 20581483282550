<template>
  <VueFinalModal
    :content-transition="!toBottom ? contentTransition : 'vfm-slide-down'"
    :content-class="!toBottom ? classes.modal : classes.bottomModal"
    :swipe-to-close="toBottom ? 'down' : undefined"
    overlay-transition="vfm-fade"
    aria-label="Модальное окно"
    @update:model-value="(val) => emit('update:modelValue', val)"
    @closed="emit('closed')"
  >
    <div v-if="blockModalState && toBottom" :class="classes.blockModal"></div>

    <div
      :class="[
        !toBottom ? classes.wrapper : classes.bottomWrapper,
        `min-h-[${minHeight}vh]`,
      ]"
      @click.self="() => emit('update:modelValue', false)"
    >
      <div :class="!toBottom ? classes.content : classes.bottomContent">
        <div
          v-if="!toBottom"
          :class="[classes.header, hasLogo ? classes.headerWithLogo : '']"
        >
          <img
            v-if="hasLogo"
            class="w-[101px] h-[25px]"
            src="/static/images/logo_white.svg"
            alt="Pedant.Market"
            width="101"
            height="25"
          >
          <h2
            v-if="title"
            :class="[classes.title, titleToLeft ? classes.titleToLeft : '']"
            v-html="title"
          ></h2>
          <BaseButton
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common:cross"
              size="32"
              class="text-ghost"
            />
          </BaseButton>
        </div>
        <div v-else-if="title" :class="classes.header">
          <h2 :class="classes.title" v-html="title"></h2>

          <BaseButton
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common:cross"
              size="32"
              class="text-ghost"
            />
          </BaseButton>
        </div>
        <div v-else-if="toBottom" :class="classes.header">
          <BaseButton
            :class="classes.button"
            aria-label="Закрыть"
            @click="emit('update:modelValue', false)"
          >
            <Icon
              name="common:cross"
              size="32"
              class="text-ghost"
            />
          </BaseButton>
        </div>
        <div :class="[classes.main, className]">
          <slot></slot>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import { toRef } from 'vue'
import { cx } from 'cva'

const props = withDefaults(defineProps<Props>(), {
  hasLogo: false,
  title: '',
  titleToLeft: false,
  contentTransition: 'vfm-fade',
  toBottom: false,
  className: '',
  minHeight: 60,
  blockModal: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void

  (e: 'closed'): void
}>()

const classes = {
  modal: cx(['absolute', 'inset-0']),
  bottomModal: cx([
    'absolute',
    'inset-x-0',
    'bottom-0',
    'w-full',
    'bg-bg-main-secondary',
    'rounded-t-xl',
    'max-w-[640px]',
    'mx-auto',
  ]),
  wrapper: cx([
    'absolute',
    'flex',
    'p-12',
    'inset-0',
    'h-full',
    'min-h-[100vh]',
    'max-h-[100vh]',
    'overflow-auto',
  ]),
  bottomWrapper: cx([
    'max-h-[90vh]',
    'h-full',
    'overflow-auto',
    'rounded-t-xl',
  ]),
  content: cx([
    'relative',
    'flex',
    'flex-col',
    'w-fit',
    'm-auto',
    'rounded-xl',
    'bg-bg-main-secondary',
    'overflow-hidden',
  ]),
  bottomContent: cx(['relative', 'flex', 'flex-col']),
  header: cx(['flex', 'flex-col', 'p-4', 'bg-bg-main-secondary']),
  headerWithLogo: cx(['w-full', 'py-[30px]', 'px-[46px]', '!bg-[#343434]']),
  title: cx([
    'w-full',
    'p-4',
    'pt-12',
    'pb-0',
    'text-2xl/[120%]',
    'font-bold',
    'text-primary',
    'text-center',
  ]),
  titleToLeft: cx(['!pl-[30px]', '!text-left']),
  button: cx(['!absolute', 'top-[15px]', 'right-[15px]', 'z-10']),
  main: cx(['p-4', 'xl:px-[46px]']),
  blockModal: cx([
    'flex',
    'absolute z-[999]',
    'w-screen',
    'h-screen',
    'bg-bg-main-primary',
    'bg-opacity-45',
    'cursor-not-allowed',
  ]),
}

interface Props {
  hasLogo?: boolean
  title?: string
  titleToLeft?: boolean
  contentTransition?: string
  toBottom?: boolean
  className?: string
  minHeight?: number
  blockModal?: boolean
}

const blockModalState = toRef(props, 'blockModal')
</script>